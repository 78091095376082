import React from 'react';

function Home() {

    return (
        <div>
            <header className="App-header">
                <div>
                    Home screen here
                </div>
                <br />

            </header>
        </div>
    );
}

export default Home;