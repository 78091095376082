import React from 'react';

const GoogleSignInButton = () => {
    React.useEffect(() => {
        try {
            const { google } = window;
            if (google) {
                google.accounts.id.initialize({
                    client_id: '921154746561-gpsoaqgqfuqrfsjdf8l7vohfkfj7b9up.apps.googleusercontent.com',
                    callback: handleCredentialResponse,
                });
                google.accounts.id.renderButton(
                    document.getElementById('buttonDiv'),
                    {
                        theme: 'outline',
                        size: 'large',
                        type: 'icon',
                        shape: 'circle',
                    },
                );
            }
        } catch (error) {
            console.error('Error initializing Google Sign-In:', error);
        }
    }, []);

    const handleCredentialResponse = (response) => {
        // Implement the logic to handle the response
    };

    return (
        <div style={{ backgroundColor: 'green', height: 100, width: 100 }}>
            <div id="buttonDiv" />
        </div>
    );
};

export default GoogleSignInButton;
