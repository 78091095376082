import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import AppleSignin from "./routes/AppleSignin";
import GoogleSignInButton from "./routes/GoogleSignInButton";
import Home from "./routes/Home";
import ReturnScreen from "./routes/ReturnScreen";
import Navbar from './Navbar';

function App() {

  return (
    <>
      <Navbar />
      <Routes >
        <Route exact path="/" element={<Home />} />
        <Route exact path="/apple" element={<AppleSignin />} />
        <Route exact path="/google" element={<GoogleSignInButton />} />
        <Route exact path="/test" element={<ReturnScreen />} />
      </Routes>
    </>
  );
}



export default App;