import { Link } from "react-router-dom"
const Navbar = () => {
    return (
        <div>
            <Link to="/">Home</Link>
            <Link to="/apple">Apple</Link>
            <Link to="/google">GoogleSignInButton</Link>
            <Link to="/test">test</Link>
        </div>
    )
}
export default Navbar;