import React, { useEffect } from 'react';

function AppleSignin() {

    const AppleSignInButton = React.useRef()


    useEffect(() => {
        console.log('useEffect')
        const clientId = 'com.signinservice.simran';
        const redirectURI = 'https://curlycodes.ca/.netlify/functions/hello-world/hello-world-post';
        const scope = 'name email';
        const state = '';
        const script = document.createElement('script');
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        script.async = true;

        const handleScriptLoad = () => {
            window.AppleID.auth.init({
                clientId,
                scope,
                redirectURI: redirectURI,
                state,
                usePopup: true,
            });
        };

        script.addEventListener('load', handleScriptLoad);
        script.addEventListener('load', () => {
            //we need make sure the script is loaded before clicking, user is not logged in, and its the home page
            console.log('script loaded')
            AppleSignInButton.current.click();
        });
        document.body.appendChild(script);

        //check if script is loaded before clicking
        console.log(window.AppleID)
        if (window.AppleID) {
            console.log('script loaded')
        }
        // 


        return () => {
            script.removeEventListener('load', handleScriptLoad);
            document.body.removeChild(script);
        };
    }, []);


    const handleAppleSignInButtonClick = () => {
        window.AppleID.auth.signIn().then((result) => {
            console.log(result);
        }).catch((err) => {
            console.log(err);
        });
    };


    return (
        <div>
            <header className="App-header">
                <div>
                    testing sign in here
                </div>
                <br />
                <button ref={AppleSignInButton} onClick={handleAppleSignInButtonClick}>Sign in with Apple</button>
            </header>
        </div>
    );
}

export default AppleSignin;